<template>
  <div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-500 mb-2'>First & Middle Name</label>
      <input type='text' v-model='author.firstName' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Last Name</label>
      <input type='text' v-model='author.lastName' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Department</label>
      <input type='text' v-model='author.department' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Affiliation</label>
      <input type='text' v-model='author.affiliation' class='border py-1 px-2 rounded block w-80'>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Country</label>
      <div>
        <vue-country-code
          @onSelect='countrySelect'
          :defaultCountry='author.country'
          :preferredCountries="['kr', 'us']">
        </vue-country-code>
        {{displayCountry}}
      </div>
    </div>
    <div class='mb-2'>
      <label class='uppercase text-sm text-gray-500 mb-2'>Email</label>
      <input type='email' v-model='author.email' class='border py-1 px-2 rounded block w-80'>
      <div v-if='!validEmail' class='mt-2 mb-6 text-red-600 text-xs text-left'>Verify email format</div>
    </div>
    <div>
      <label class='flex flex-row justify-start items-center gap-x-1'>
        <input type='checkbox' v-model='author.isPresenter'>
        <span :for='author.isPresenter'>Presenter</span>
      </label>
      <label class='flex flex-row justify-start items-center gap-x-1'>
        <input type='checkbox' v-model='author.isCorrespondant'>
        <span :for='author.isCorrespondant'>Corresponding Author</span>
      </label>
    </div>
  </div>
</template>

<script>
import VueCountryCode from 'vue-country-code'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AbstractSubmissionEditAuthor',
  components: {
    VueCountryCode,
  },
  computed: {
    ...mapFields('abstracts', {
      author:'editingAuthor',
    }),
    validEmail () {
      let mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return !this.author.email || mailformat.test(this.author.email)
    },
  },
  data () {
    return {
      displayCountry: '',
    }
  },
  methods: {
    countrySelect ({name, iso2}) {
      this.displayCountry = name
      this.author.country = iso2
    },
  },
}
</script>
