<template>
  <div>
    <div class='font-semibold'><span class='font-bold text-red-500'>*</span> {{ disclosure.question }}</div>
    <component 
      :is='inputType' 
      :disclosure='disclosure'
      class='pl-4' />
  </div>
</template>

<script>
import DisclosureMultiSelect from '@/components/abstracts/DisclosureMultiSelect.vue'
import DisclosureRadio       from '@/components/abstracts/DisclosureRadio.vue'

export default {
  name: 'DisclosureSubmission',
  props: ['disclosure'],
  components: {
    DisclosureMultiSelect,
    DisclosureRadio,
  },
  computed: {
    inputType () {
      return `disclosure-${this.disclosure.answerType}`
    },
  },
}
</script>
