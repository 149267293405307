<template>
  <div class='abstract-submission-container flex flex-col items-stretch lg:flex-row min-h-screen'>
    <div
      class='w-full lg:w-80 flex-grow-0 flex-shrink-0 px-8 pt-2 pb-8 lg:py-16 text-gray-50 flex flex-col items-start justify-start relative'
      :style='backgroundStyle'>
      <h1
        class='text-normal font-light border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToAbstractSubmissions'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        My Abstract Submissions
      </h1>
      <h1 class='text-2xl lg:text-3xl font-light lg:mt-4'>
        Abstract Submission
      </h1>
      <button
        class='mt-8 border border-solid border-transparent hover:border-gray-300 py-2 px-4 text-center text-gray-200 text-sm uppercase block rounded-md  hidden lg:flex flex-row items-center justify-center'
        style='margin-left:-1.5rem;'
        @click='openPreview'>
        <file-search-icon class='h-4' />
        Preview Submission
      </button>
      <button
        class='logout-button text-xs text-gray-50 uppercase hover:shadow-md border border-transparent hover:border-gray-50 rounded-md py-2 absolute'
        @click='logoutApp'>
        Logout
      </button>
    </div>
    <div class='p-8 lg:p-12 overflow-y-auto h-screen flex-grow relative'>
      <div class='max-w-2xl'
        style='padding-bottom: 6rem;'>
        <section>
          <h3 class='text-xl font-semibold mt-4'>Disclosures</h3>
          <h3 class='text-gray-600 text-sm font-normal hidden'>Fields marked with a (<span class='font-bold text-red-500'>*</span>) are required for submission.</h3>
          <div class='text-sm p-4 rounded-md bg-gray-100 mt-4'>
          <h3 class='text-semibold mb-4'>
            Disclosures for Presented Papers and Authors in The 66th Annual Hybrid Congress of the Korean Orthopaedic Association
          </h3>
          <disclosure-submission v-for='disclosure in disclosures'
            :key='`disclosure-${disclosure.question}`'
            :disclosure='disclosure'
            class='mb-4' />
          </div>
        </section>

        <section class='mt-8'>
          <h3 class='text-xl font-semibold'>
            Authors
          </h3>
          <div class='text-gray-500 text-sm font-normal normal-case mb-4'>Drag-and-drop to re-order authors.</div>
          <section>
            <div>
              <table class='w-full text-xs'>
                <thead>
                  <tr class='text-sm text-gray-600'>
                    <th class='text-left px-2 py-2'>Name</th>
                    <th class='text-left px-2 py-2'>Department</th>
                    <th class='text-left px-2 py-2'>Affiliation</th>
                    <th class='text-left px-2 py-2'>Country</th>
                    <th class='text-left px-2 py-2'>Email</th>
                    <th class='text-right pl-2 py-2'></th>
                  </tr>
                </thead>
                <draggable v-model='authorList' tag='tbody'>
                  <tr v-for='author in authorList'
                    :key='`author-${author.authorIndex}`'
                    class='border-t cursor-move hover:bg-gray-100'>
                      <td class='text-left px-2 py-2'>{{author.firstName}} {{author.lastName}}</td>
                      <td class='text-left px-2 py-2'>{{author.department}}</td>
                      <td class='text-left px-2 py-2'>{{author.affiliation}}</td>
                      <td class='text-left px-2 py-2'>{{author.country}}</td>
                      <td class='text-left px-2 py-2'>{{author.email}}</td>
                      <td class='text-left px-2 py-2 text-xs'>
                        <div v-if='author.isPresenter'>Presenter</div>
                        <div v-if='author.isCorrespondant' class='whitespace-nowrap'>Corresponding Author</div>
                      </td>
                      <td class='text-right pl-2 py-2 flex flex-row justify-end items-center gap-x-1'>
                        <button
                          @click='editAuthor(author)'
                          class='hover:bg-gray-200 p-1 rounded-md'>
                          <edit-icon class='h-4 ' />
                        </button>
                        <button
                          @click='removeAuthor(author)'
                          class='hover:bg-gray-200 p-1 rounded-md'>
                          <x-icon class='h-4'/>
                        </button>
                      </td>
                    </tr>
                </draggable>
              </table>
              <div>
                <button
                  @click='addAuthor'
                  class='border border-solid border-gray-200 hover:border-gray-500 py-2 px-8 w-full text-center text-gray-800 text-sm uppercase block rounded-md hover:shadow-md'>
                  Add Author
                </button>
              </div>
            </div>
          </section>

          <section class='mt-8'>
            <h3 class='text-xl font-semibold'>
              Abstract Overview
            </h3>
            <div class='text-gray-500 text-sm font-normal mb-4'>
              Fields marked with a (<span class='font-bold text-red-500'>*</span>) are required for submission.
            </div>

            <div class='mb-6'>
              <label class='uppercase text-sm text-gray-500'>
                Presentation Type
                <span class='capitalize text-xs font-semibold text-red-500'>*</span>
              </label>
              <h3 class='text-gray-500 text-xs font-normal mb-2'>All presentations are expected to be conducted in <span class='text-red-500 font-bold'>English</span></h3>
              <label class='flex flex-row justify-start items-center gap-x-1 text-sm'>
                <input type='radio' id='oral' value='Oral' v-model='preferredPresentationType'>
                <span>Oral</span>
              </label>
              <label class='flex flex-row justify-start items-center gap-x-1 text-sm'>
                <input type='radio' id='poster' value='Poster' v-model='preferredPresentationType'>
                <span>Poster</span>
              </label>
              <label class='flex flex-row justify-start items-center gap-x-1 text-sm'>
                <input type='radio' id='oral_or_poster' value='Oral or Poster' v-model='preferredPresentationType'>
                <span>Oral or Poster</span>
              </label>
              <label class='flex flex-row justify-start items-center gap-x-1 text-sm'>
                <input type='radio' id='video' value='Video' v-model='preferredPresentationType'>
                <span>Video</span>
              </label>
            </div>

            <div class='mb-6'>
              <label class='uppercase text-sm text-gray-500 mb-2'>
                Category
                <span class='capitalize text-xs font-semibold text-red-500'>*</span>
              </label>
              <select
                v-model='category'
                class='border py-1 px-2 rounded block w-80'>
                <option disabled></option>
                <option value='Original Article'>Original Article</option>
                <option value='Case Presentation'>Case Presentation</option>
                <option value='Technical Note'>Technical Note</option>
              </select>
            </div>
            <div class='mb-6'>
              <label class='uppercase text-sm text-gray-500 mb-2'>
                Field
                <span class='capitalize text-xs font-semibold text-red-500'>*</span>
              </label>
              <select
                v-model='field'
                class='border py-1 px-2 rounded block w-80'>
                <option disabled></option>
                <option value='Knee'>Knee</option>
                <option value='Tumor'>Tumor</option>
                <option value='Hip'>Hip</option>
                <option value='Shoulder and Elbow'>Shoulder and Elbow</option>
                <option value='Spine'>Spine</option>
                <option value='Foot and Ankle'>Foot and Ankle</option>
                <option value='Fracture'>Fracture</option>
                <option value='Paediatrics'>Paediatrics</option>
                <option value='Hand'>Hand</option>
                <option value='Basic Researches'>Basic Researches</option>
                <option value='Microsurgery'>Microsurgery</option>
                <option value='Others'>Others</option>
              </select>
            </div>
            <div class='mb-6'>
              <label class='uppercase text-sm text-gray-500 mb-2'>
                Abstract Title
                <span class='capitalize text-xs font-semibold text-red-500'>*</span>
              </label>
              <input
                type='text'
                v-model='title'
                class='border py-1 px-2 rounded block w-80'>
            </div>
          </section>

          <section>
            <h3 class='text-xl font-semibold mt-4'>
              Abstract Details
            </h3>
            <div class='text-gray-500 text-sm font-normal mb-4'>Please enter all abstract information in English.</div>
            <div v-if='articleType'>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Introduction</label>
                <vue-editor
                  class='introduction'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".introduction")'
                  v-model='introduction'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Materials and Methods</label>
                <vue-editor
                  class='methods'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".methods")'
                  v-model='methodsAndMaterials'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Results</label>
                <vue-editor
                  class='results'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".results")'
                  v-model='results'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Conclusions</label>
                <vue-editor
                  class='conclusions'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".conclusions")'
                  v-model='conclusions'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
            </div>
            <div v-else-if='caseType'>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Case Presentation</label>
                <vue-editor
                  class='casePresentation'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".casePresentation")'
                  v-model='casePresentation'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
            </div>
            <div v-else-if='technicalType'>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Technical Note</label>
                <vue-editor
                  class='technicalNote'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".technicalNote")'
                  v-model='technicalNote'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
            </div>
            <div>
              <div class='mb-6'>
                <label class='uppercase text-sm text-gray-500 mb-2'>Acknowledgements</label>
                <vue-editor
                  class='acknowledgements'
                  @focus='onEditorFocus'
                  @ready='addSymbolsAndHelp(".acknowledgements")'
                  v-model='acknowledgements'
                  :editor-options='editorOptions'>
                </vue-editor>
              </div>
            </div>
            <div class='mb-6'>
              <label class='uppercase text-sm text-gray-500 mb-2'>
                Keywords
                <span class='capitalize text-xs font-semibold text-red-500'>*</span>
                <span class='inline-block text-gray-500 text-xs normal-case'>(Please add one keyword at a time)</span>
              </label>
              <div>
                <el-tag
                  :key='`keyword-${word}`'
                  v-for='word in keywords'
                  closable
                  :disable-transitions='false'
                  @close='handleRemove(word)'>
                  {{word}}
                </el-tag>
                <el-input
                  class='input-new-keyword'
                  v-if='keywordVisible'
                  v-model='keywordValue'
                  ref='saveKeywordInput'
                  size='mini'
                  @keyup.enter.native='handleKeywordConfirm'
                  @blur='handleKeywordConfirm'>
                </el-input>
                <el-button v-else class='button-new-keyword' size='small' @click='showKeywordInput'>+ Keyword</el-button>
              </div>
            </div>
          </section>
        </section>
        <ul class='text-sm text-red-500 list-disc'>
          <li v-if='!hasAllAnswersDisclosures'>Please answer all disclosure questions.</li>
          <li v-if='!preferredPresentationType'>Please select a presentation type.</li>
          <li v-if='!category'>Please specify a category.</li>
          <li v-if='!field'>Please specify a field.</li>
          <li v-if='!title'>Please specify an abstract title.</li>
          <li v-if='!hasKeywords'>Please specify keywords.</li>
          <li v-if='!underWordLimit'>Please keep the total word limit under {{wordLimit}}</li>
        </ul>
        <div class='action-button-bar fixed bottom-0 right-0 bg-gray-100 p-4 flex flex-row justify-start items-center gap-x-8'>
          <button
            class='border py-3 px-16 text-center text-white text-sm uppercase  bg-gray-900 block rounded-md'
            :class='disabledPreviewAndSubmitButtonStyle'
            :disabled='!canSubmit'
            @click='openPreview'>
            Preview and Submit
          </button>
          <div class='uppercase text-sm' :class='overWordLimitStyle'>word limit: {{totalWordCount}} / {{wordLimit}}</div>
        </div>
      </div>
    </div>
    <modal
      name='edit-author'
      :adaptive='true'
      :height='720'>
      <div class='p-8 h-full overflow-y-auto'>
        <h2 class='text-2xl font-semibold'>Author Details</h2>
        <h3 class='text-gray-500 text-base font-normal'><span class='text-red-500 font-bold'>*</span>Please enter all information in English.</h3>
        <abstract-submission-edit-author class='my-8' />
        <button
          class='border py-2 px-8 text-center text-white text-sm uppercase bg-gray-900 block rounded-md w-64'
          @click='closeEditAuthorModal'>
          Done
        </button>
      </div>
    </modal>
    <modal
      name='preview-submission'
      :adaptive='true'
      :height='"85%"'
      :width='"90%"'>
      <div class='h-full relative'>
        <abstract-submission-preview
          :abstract='editingAbstractSubmission' class='overflow-y-auto pb-16' style='height: 100%;' />
        <div class='absolute bottom-0 right-0 bg-gray-100 p-4 flex flex-row justify-start items-center gap-x-8 w-full'>
          <button v-if='canSubmit'
            class='border py-3 px-16 text-center text-white text-sm uppercase bg-gray-900 block rounded-md'
            @click='submit'>
            Submit
          </button>
          <ul v-else
            class='text-sm text-red-500'>
            <li v-if='!hasAllAnswersDisclosures' class='inline-block mr-1'>Please answer all disclosure questions.</li>
            <li v-if='!preferredPresentationType' class='inline-block mr-1'>Please select a presentation type.</li>
            <li v-if='!category' class='inline-block mr-1'>Please specify a category.</li>
            <li v-if='!field' class='inline-block mr-1'>Please specify a field.</li>
            <li v-if='!title' class='inline-block mr-1'>Please specify an abstract title.</li>
            <li v-if='!hasKeywords' class='inline-block mr-1'>Please specify keywords.</li>
          </ul>
          <h3 v-if='canSubmit'
            class='text-gray-500 text-base font-normal'>
            All presentations are expected to be conducted in
            <span class='text-red-500 font-bold'>English</span>
          </h3>
        </div>
        <button
          class='p-2 absolute top-2 right-2 hover:bg-gray-200'
          @click='closePreview'>
          <x-icon class='h-5' />
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { XIcon, EditIcon, ArrowNarrowLeftIcon, FileSearchIcon } from 'vue-tabler-icons'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { VueEditor } from 'vue2-editor'
import draggable from 'vuedraggable'
import cloneDeep from 'lodash/cloneDeep'

import DisclosureSubmission         from '@/components/abstracts/DisclosureSubmission.vue'
import AbstractSubmissionEditAuthor from '@/components/abstracts/AbstractSubmissionEditAuthor.vue'
import AbstractSubmissionPreview    from '@/components/abstracts/AbstractSubmissionPreview.vue'

export default {
  name: 'AbstractSubmission',
  components: {
    DisclosureSubmission,
    AbstractSubmissionEditAuthor,
    AbstractSubmissionPreview,
    draggable,
    VueEditor,
    EditIcon,
    XIcon,
    ArrowNarrowLeftIcon,
    FileSearchIcon,
  },
  data () {
    return {
      editorFocused: null,
      editorOptions:{
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
              [{symbol: [false, 'α', 'β', 'π']}], // custom symbol dropdown
              ['clean'],
            ],
            handlers: {
              symbol: (value) => {
                if (this.editorFocused && value) {
                  const cursorPosition = this.editorFocused.getSelection().index
                  this.editorFocused.insertText(cursorPosition, value)
                  this.editorFocused.setSelection(cursorPosition + value.length)
                }
              }
            }
          }
        }
      },
      keywordVisible: false,
      keywordValue: '',
    }
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
    ]),
    ...mapFields('abstracts', [
      'editingAbstractSubmission.disclosures',
      'editingAbstractSubmission.preferredPresentationType',
      'editingAbstractSubmission.category',
      'editingAbstractSubmission.field',
      'editingAbstractSubmission.title',
      'editingAbstractSubmission.introduction',
      'editingAbstractSubmission.methodsAndMaterials',
      'editingAbstractSubmission.results',
      'editingAbstractSubmission.conclusions',
      'editingAbstractSubmission.casePresentation',
      'editingAbstractSubmission.technicalNote',
      'editingAbstractSubmission.acknowledgements',
      'editingAbstractSubmission.keywords',
    ]),
    ...mapState('abstracts', [
      'editingAbstractSubmission',
      'wordLimit',
      'editingAuthor',
    ]),
    ...mapState('users', [
      'hasValidToken',
      'profile',
    ]),
    hasAllAnswersDisclosures () {
      return this.disclosures.every(disclosure => {
        if (disclosure.answerType === 'multi-select') {
          return disclosure.answers.length > 0
        } else if (disclosure.requiredDetails && disclosure.answer !== 'None') {
          return disclosure.answer && disclosure.collectDetails.every(detail => detail.answer)
        } else {
          return disclosure.answer
        }
      })
    },
    hasKeywords () {
      return this.keywords.length > 0
    },
    underWordLimit () {
      return this.totalWordCount < this.wordLimit
    },
    disabledPreviewAndSubmitButtonStyle () {
      return (!this.canSubmit) ? 'opacity-25 cursor-not-allowed' : ''
    },
    overWordLimitStyle () {
      return !this.underWordLimit ? 'text-red-500 font-semibold' : ''
    },
    canSubmit () {
      return this.hasAllAnswersDisclosures &&
             this.preferredPresentationType &&
             this.category &&
             this.field &&
             this.title &&
             this.hasKeywords
    },
    authorList: {
      get () {
        return this.editingAbstractSubmission.authors
      },
      set (newList) {
        this.updateAuthors(newList)
      }
    },
    articleType () {
      return this.category.match(/article/gi)
    },
    caseType () {
      return this.category.match(/case/gi)
    },
    technicalType () {
      return this.category.match(/technical/gi)
    },
    introductionWordCount () {
      return this.wordCount(this.introduction)
    },
    methodsWordCount () {
      return this.wordCount(this.methodsAndMaterials)
    },
    resultsWordCount () {
      return this.wordCount(this.results)
    },
    conclusionsWordCount () {
      return this.wordCount(this.conclusions)
    },
    casePresentationWordCount () {
      return this.wordCount(this.casePresentation)
    },
    technicalNoteWordCount () {
      return this.wordCount(this.technicalNote)
    },
    acknowledgementsWordCount () {
      return this.wordCount(this.acknowledgements)
    },
    totalWordCount () {
      if (this.articleType) {
        return this.introductionWordCount +
          this.methodsWordCount +
          this.resultsWordCount +
          this.conclusionsWordCount +
          this.acknowledgementsWordCount
      } else if (this.caseType) {
        return this.casePresentationWordCount + this.acknowledgementsWordCount
      } else if (this.technicalType) {
        return this.technicalNoteWordCount + this.acknowledgementsWordCount
      } else {
        return this.acknowledgementsWordCount
      }
    },
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'logout',
      'getProfile',
    ]),
    ...mapActions('abstracts', [
      'saveMyAbstract',
      'getEditingAbstract',
      'resetEditingAuthor',
    ]),
    ...mapMutations('abstracts', [
      'addNewAuthor',
      'updateAuthors',
      'removeAuthor',
      'setNewAbstract',
      'setEditingAuthor',
    ]),
    goToAbstractSubmissions () {
      this.$router.push({ name: 'AbstractSubmissions' })
    },
    addAuthor () {
      this.resetEditingAuthor()
      this.$modal.show('edit-author')
    },
    editAuthor (author) {
      this.setEditingAuthor(author)
      this.$modal.show('edit-author')
    },
    closeEditAuthorModal () {
      if (this.editingAuthor.authorIndex === -1) {
        this.addNewAuthor(this.editingAuthor)
      }
      this.$modal.hide('edit-author')
    },
    openPreview () {
      this.$modal.show('preview-submission')
    },
    closePreview () {
      this.$modal.hide('preview-submission')
    },
    onEditorFocus (quill) {
      this.editorFocused = quill
    },
    addSymbolsAndHelp (nodeClass) {
      let symbolPickerItems = Array.prototype.slice.call(document.querySelectorAll(`${nodeClass} .ql-symbol .ql-picker-item`))
      symbolPickerItems.forEach(item => item.textContent = item.dataset.value)
      Array.prototype.slice.call(document.querySelectorAll(`${nodeClass} .ql-symbol .ql-picker-label`)).forEach((node) => node.innerHTML = '<span class="pr-3">Ω</span>' + node.innerHTML)

      Array.prototype.slice.call(document.querySelectorAll(`${nodeClass} .ql-tooltip`)).forEach((node) => node.innerHTML = node.innerHTML + '<a class="pl-3" target="_blank" href="https://katex.org/">help</a>')
    },
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    wordCount (string) {
      let words = cloneDeep(string)
      words = words.replace(/(^\s*)|(\s*$)/gi, '') // exclude start and end white-space
      words = words.replace(/[ ]{2,}/gi, ' ') // 2 or more space to 1
      words = words.replace(/\n /, '\n') // exclude newline with a start spacing
      return words.split(' ').filter((str) => str !== '').length
    },
    submit () {
      this.saveMyAbstract().then(() => {
        this.$router.push({ name: 'AbstractSubmissions' })
      })
    },
    handleRemove (word) {
      this.keywords.splice(this.keywords.indexOf(word), 1)
    },
    showKeywordInput () {
      this.keywordVisible = true
      this.$nextTick(() => {
        this.$refs.saveKeywordInput.$refs.input.focus()
      })
    },
    handleKeywordConfirm () {
      let keywordValue = this.keywordValue
      if (keywordValue) {
        this.keywords.push(keywordValue)
      }
      this.keywordVisible = false
      this.keywordValue = ''
    }
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissions'} })
      } else {
        let abstractId = parseInt(this.$route.query.abstract_submission_id)
        if (abstractId === 0) {
          this.setNewAbstract()
          this.getProfile().then(() => {
            // set default author as user
            this.editingAbstractSubmission.authors[0].email = this.profile.email
            this.editingAbstractSubmission.authors[0].firstName = this.profile.firstName
            this.editingAbstractSubmission.authors[0].lastName = this.profile.lastName
            this.editingAbstractSubmission.authors[0].department = this.profile.department
            this.editingAbstractSubmission.authors[0].affiliation = this.profile.organization
            this.editingAbstractSubmission.authors[0].country = this.profile.country
            this.editingAbstractSubmission.authors[0].isPresenter = true
            this.editingAbstractSubmission.authors[0].isCorrespondant = true
          })
        } else {
          this.getEditingAbstract(abstractId)
        }
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss' scoped>
  .action-button-bar {
    width: 100%;
  }
  @media (min-width: 1024px) {
    .action-button-bar {
      width: calc(100vw - 20rem);
    }
  }
</style>
