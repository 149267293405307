<template>
  <div>
    <label v-for='choice in disclosure.choices' 
      :key='`choice-${disclosure.question}-${choice}`'
      class='flex flex-row justify-start items-center gap-x-1'>
      <input type='checkbox' v-model='disclosure.answers' :value='choice' @change='handleNone'>
      <span>{{ choice }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'DisclosureMultiSelect',
  props: [
    'disclosure'
  ],
  methods: {
    handleNone (e) {
      if (e.target.value.match(/none/i) && e.target.checked) {
        // only keep none
        this.disclosure.answers = [e.target.value]
      } else {
        let noneIndex = this.disclosure.answers.findIndex(answer => answer.match(/none/i))
        if (noneIndex > -1) {
          // remove none
          this.disclosure.answers.splice(noneIndex, 1)
        }
      }
    },
  },
}
</script>
