<template>
  <div class='mb-4'>
    <label class='uppercase text-sm text-gray-500 block'>
      {{ label }}
    </label>
    <input 
      type='text' 
      @input='handleInput' 
      :value='value' 
      class='border py-1 px-2 rounded block w-64'>
  </div>
</template>

<script>
export default {
  name: 'DisclosureDetail',
  props: ['label', 'value'],
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
