<template>
  <div>
    <label v-for='choice in disclosure.choices' 
      :key='`choice-${disclosure.question}-${choice}`'
      class='flex flex-row justify-start items-center gap-x-1'>
      <input type='radio' :value='choice' v-model='disclosure.answer'>
      <span>{{ choice }}</span>
    </label>
    <disclosure-detail v-for='detail in details'
      :key='`detail-${detail.key}`'
      :label='detail.label'
      v-model='detail.answer' />
  </div>
</template>

<script>
import DisclosureDetail from '@/components/abstracts/DisclosureDetail.vue'

export default {
  name: 'DisclosureRadio',
  props: ['disclosure'],
  components: {
    DisclosureDetail,
  },
  computed: {
    details () {
      return this.disclosure.collectDetails ? this.disclosure.collectDetails.filter(detail => detail.onChoice === this.disclosure.answer) : []
    },
    hasDetails () {
      return this.details && this.details.length > 0
    },
  },
}
</script>
